<template>
  <div class="advice-content">
    <div class="bg-img">
      <img src="../../assets/images/enterprise/advice/bg.png" alt="" />
    </div>
    <div class="textarea">
      <el-input
        type="textarea"
        :maxlength="500"
        placeholder="请输入内容"
        v-model="textarea"
      >
      </el-input>
    </div>
    <img
      class="submit"
      src="../../assets/images/enterprise/advice/submit.png"
      alt="提交"
      @click="submit"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      textarea: "",
    };
  },
  methods: {
    submit() {
      this.$api.advice.add({ content: this.textarea }).then(() => {
        this.textarea = "";
      });
    },
  },
};
</script>
<style>
.el-textarea {
  height: 100%;
}
.el-textarea__inner {
  height: 100%;
}
</style>
<style lang="less" scoped>
.advice-content {
  position: relative;
  .bg-img {
    position: absolute;
    width: 90%;
    top: 0;
    left: 5%;
    z-index: -1;
    height: 32vw;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .textarea {
    width: 60vw;
    height: 22vw;
    margin: 0 auto;
    position: relative;
    top: 5vw;
  }
  .submit {
    width: 10vw;
    cursor: pointer;
    position: relative;
    top: 10vw;
  }
}
</style>
